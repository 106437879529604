// import { ajax } from '@/js/extension'
import axios from 'axios'

const BASE_URL = '/temporary-restapi'

const AssistApi = {
  createInstantCard (data, success, failure) {
    return axios.post(
      BASE_URL + '/debit-card/core/lpp/card/create?loanId=' +
      data.loanId + '&expMonth=' + data.expMonth + '&expYear=' + data.expYear + '&cardNumber=' + data.cardNumber,
      null,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          INTERNAL_TOKEN: 'ANT_8306'
        }
      }
    ).then(result => {
      return resultHandler(result, Promise.resolve, Promise.reject, success, failure)
    })
  }
}

function resultHandler (result, resolve, reject, success, failure) {
  const resultData = result.data
  if (result.config.url.includes('/ibv/get-page')) {
    success(resultData)
  } else if (result.status === 200) {
    if (resultData.code < 0) {
      if (failure) {
        failure(resultData)
      }

      return Promise.reject(result)
    } else {
      if (success) {
        success(resultData.data)
      }

      return Promise.resolve(resultData.data)
    }
  } else {
    if (failure) {
      failure(resultData)
    }

    return Promise.reject(result.statusText)
  }
}
export { AssistApi }
