<template>
  <div style="max-width: 800px; margin: auto;">
    <v-card max-width="90%" class="p-t-30" style="margin: auto; height: calc(100vh)" flat>
      <a style="padding-left: 16px" :href="portfolio.website" target="_blank">
        <img
          :src="portfolio.logo"
          class="height-30"
        />
      </a>
      <div>
        <v-card-text class="p-b-2" style="line-height: 1" :style="`color: ${$vuetify.theme.themes.light.primary} !important`">Please input your debit card information</v-card-text>
        <v-row>
          <v-col cols="12" class="p-t-40">
            <v-card-text class="f-w-600">
                Card Number:
            </v-card-text>
            <v-card-text class="p-t-0">
              <input
                type="number"
                v-model="instantCard.cardNumber"
                style="padding: 5px; border: 1px solid rgb(133, 133, 133); width: 100%; border-radius: 3px; text-align: left; outline-color: var(--v-primary-base)"/>
            </v-card-text>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card-text class="f-w-600">Expiration Date:</v-card-text>
            <v-card-text class="p-t-0">
              <v-row>
                <v-col class="p-t-0" cols="7">
                  <v-select
                    class="m-t-0"
                    :items="mouth"
                    v-model="instantCard.expMonth"></v-select>
                </v-col>
                <v-col class="p-t-0" cols="5">
                  <v-select
                    class="m-t-0"
                    :items="year"
                    v-model="instantCard.expYear"></v-select>
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>
        </v-row>
      </div>
      <v-card-text>By adding your debit card as a payment method you can avoid possible bank fees associated with return items and non-sufficient funds.</v-card-text>
      <v-card-actions class="width-full" style="padding-left: 16px; bottom: 0px; position: absolute; padding-bottom: 85px">
        <div align="center" style="margin: auto;">
          <v-row>
            <div style="margin-left: 10px">
              <v-img height="25px" width="40px" src="/static/images/card-visa.png"></v-img>
            </div>
            <div style="float: right;margin-left: 2px">
              <v-img height="25px" width="40px" src="/static/images/card-mastercard.png"></v-img>
            </div>
          </v-row>
        </div>
      </v-card-actions>
      <v-card-actions class="width-full" style="padding-left: 16px; bottom: 0px; position: absolute; padding-bottom: 30px">
        <v-row>
          <v-btn
            x-large
            block
            color="primary"
            class="width-150"
            @click="submit"
          >Submit
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-overlay
      absolute
      :value="overlay"
    >
      <v-icon class="mdi mdi-spin mdi-loading" large></v-icon>
    </v-overlay>
  </div>
</template>
<script>
import { OriginationApi } from '../../api/application'
import { AssistApi } from '../../api/assist'
import { MaintenanceApi } from '../../api/maintenance'
import { DataType, Tool } from '../../js/core'
const tool = new Tool()

export default {
  data: function () {
    return {
      overlay: false,
      menu: false,
      mouth: [
        { text: '01', value: 1 },
        { text: '02', value: 2 },
        { text: '03', value: 3 },
        { text: '04', value: 4 },
        { text: '05', value: 5 },
        { text: '06', value: 6 },
        { text: '07', value: 7 },
        { text: '08', value: 8 },
        { text: '09', value: 9 },
        { text: '10', value: 10 },
        { text: '11', value: 11 },
        { text: '12', value: 12 }
      ],
      year: [],
      instantCard: {
        cardNumber: '',
        expMonth: '',
        expYear: '',
        loanId: undefined
      },
      loanDetail: {},
      portfolio: {}
    }
  },
  async created () {
    const year = new Date().getFullYear()
    this.instantCard.expYear = year
    for (let i = 0; i < 10; i++) {
      this.year.push(year + i)
    }
    const _this = this
    await OriginationApi.getLoanIdDecrypt({ loanId: this.getLoanIdEncrypt() }, function (result) {
      const params = result.split('#')
      tool.setItem('LI', params[0])
      tool.setItem('AT', params[1])
      _this.instantCard.loanId = params[0]
    })
    await OriginationApi.getLoanById({ loanId: _this.instantCard.loanId, feature: 'Loan' }, function (result) {
      _this.loanDetail = result
    })
    await MaintenanceApi.getPortfolioDetailByPortfolioId({ portfolioId: _this.loanDetail.portfolioId }, function (result) {
      _this.portfolio = result
      localStorage.setItem(DataType.COOKIE_KEY.PORTFOLIO, JSON.stringify(result))
    })
  },
  methods: {
    submit () {
      const _this = this
      if (_this.checkParams()) {
        return false
      }
      _this.overlay = true
      AssistApi.createInstantCard(
        _this.instantCard,
        function (result) {
          _this.$router.push('/assist/success')
        },
        function (error) {
          _this.$swal({
            type: 'warning',
            text: error.message
          }).then(function () {
            _this.overlay = false
          })
        })
    },
    getLoanIdEncrypt () {
      return this.$route.params.uuid
    },
    checkParams () {
      return tool.isEmpty(this.instantCard.cardNumber) ||
        tool.isEmpty(this.instantCard.expMonth) ||
        tool.isEmpty(this.instantCard.loanId) ||
        tool.isEmpty(this.instantCard.expYear)
    }
  }
}
</script>

<style lang="css">
  .v-input__slot {
    border: 1px solid rgb(133, 133, 133);
    padding: 5px;
    border-radius: 3px !important;
  }
</style>
